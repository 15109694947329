import * as React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import Button from "../components/Reusable/Button"
import Image from "../components/image"

const NotFoundPage = () => {
  const [image, setImage] = React.useState(false)

  React.useLayoutEffect(() => {
    function responsiveImage() {
      if (window.matchMedia("(max-width: 450px)").matches) {
        setImage(true)
      } else {
        setImage(false)
      }
    }
    window.addEventListener("resize", responsiveImage)
    responsiveImage()
    return () => window.removeEventListener("resize", responsiveImage)
  }, [])

  return (
    <Layout>
      <Styled>
        <div>
          {!image ? (
            <div className="img">
              <Image imgName="404.png" alt="error404" />
            </div>
          ) : (
            <div className="img">
              <Image imgName="404Phone.png" alt="maintenance" />
            </div>
          )}
          <h3>Not Found</h3>
          <p>Ooops, kami tidak menemukan halaman yang kamu minta.</p>
          <div>
            <Button to="/">Kembali ke halaman utama</Button>
          </div>
        </div>
      </Styled>
    </Layout>
  )
}

const Styled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  width: 100%;
  text-align: center;
  color: #4f473f;

  h3 {
    font-size: 32px;
  }

  p {
    font-size: 24px;
    padding: 0px 20px;
  }

  .img {
    width: 100vh;
    margin: 50px 0;
  }

  @media only screen and (max-width: 991.98px) {
    .img {
      width: 70vh;
      padding: 0px 45px;
    }

    h3 {
      font-size: 24px;
    }

    p {
      font-size: 16px;
      padding: 0px 20px;
    }
  }

  @media only screen and (max-width: 511.98px) {
    height: 80vh;

    .img {
      width: 45vh;
      padding: 0px 10px;
      margin: auto;
      margin-bottom: 35px;
    }

    h3 {
      font-size: 24px;
    }

    p {
      font-size: 16px;
      padding: 0px 20px;
    }
  }

  @media only screen and (max-width: 300px) {
    height: 80vh;

    .img {
      width: auto;
      padding: 0px 10px;
      margin: auto;
      margin-bottom: 35px;
    }

    h3 {
      font-size: 24px;
    }

    p {
      font-size: 16px;
      padding: 0px 20px;
    }
  }
`

export default NotFoundPage
